import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importações diretas dos arquivos de tradução
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';

// Recursos de tradução
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

i18n
  // Passar o i18n para react-i18next
  .use(initReactI18next)
  // Inicializar i18next
  .init({
    resources,
    lng: 'pt', // Idioma padrão
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false, // não é necessário para React
    },
    react: {
      useSuspense: false, // evitar problemas com Suspense
    }
  });

export default i18n;