import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-gray-50 py-8 border-t">
      <Container>
        {/* Conteúdo Principal */}
        <div className="flex flex-col md:flex-row flex-wrap justify-between gap-8 px-4">
          {/* Links */}
          <div className="w-full md:w-auto">
            <h5 className="text-lg font-semibold mb-4 text-gray-800">Links</h5>
            <div className="flex flex-wrap gap-4 md:gap-6">
              <Link to="/" className="text-gray-600 hover:text-gray-800 transition-colors">
                {t('navigation.inicio')}
              </Link>
              <Link to="/texto" className="text-gray-600 hover:text-gray-800 transition-colors">
                {t('navigation.textos')}
              </Link>
              <Link to="/insercoes" className="text-gray-600 hover:text-gray-800 transition-colors">
                {t('navigation.insercoesNaMidia')}
              </Link>
              <Link to="/reel" className="text-gray-600 hover:text-gray-800 transition-colors">
                {t('navigation.resumeReel')}
              </Link>
            </div>
          </div>

          {/* Contato */}
          <div className="w-full md:w-auto">
            <h5 className="text-lg font-semibold mb-4 text-gray-800">{t('navigation.contato')}</h5>
            <div className="flex items-center text-gray-600">
              <svg className="w-5 h-5 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <a href="mailto:lopescatarina17@gmail.com" className="hover:text-gray-800 transition-colors break-all">
              lopescatarina17@gmail.com
              </a>
            </div>
          </div>

          {/* Documentos */}
          <div className="w-full md:w-auto">
            <h5 className="text-lg font-semibold mb-4 text-gray-800">{t('navigation.documentos')}</h5>
            <div className="flex gap-4 md:gap-6">
              <Link to="/cv" className="text-gray-600 hover:text-gray-800 transition-colors">
                {t('navigation.cv')}
              </Link>
              <Link to="/lattes" className="text-gray-600 hover:text-gray-800 transition-colors">
                {t('navigation.lattes')}
              </Link>
            </div>
          </div>

          {/* Redes Sociais */}
          <div className="w-full md:w-auto">
            <h5 className="text-lg font-semibold mb-4 text-gray-800">{t('navigation.redesSociais')}</h5>
            <div className="flex gap-4">
              {/* LinkedIn */}
              <a 
                href="https://www.linkedin.com/in/lopescatarina" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-emerald-500 transition-colors"
                aria-label="LinkedIn"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              </a>

              {/* Twitter/X */}
              <a 
                href="https://x.com/lopescat_" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900 transition-colors"
                aria-label="Twitter"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright e Créditos */}
        <div className="pt-8 mt-8 border-t border-gray-200 px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <p className="text-center text-gray-600 text-sm">
              {t('common.copyright').replace('{year}', currentYear)}
            </p>
            <a
              href="https://www.linkedin.com/in/giodelladea"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-500 hover:text-[#b4d3b2] transition-colors inline-flex items-center gap-1"
            >
              {/* Usar a tradução sem adicionar "por solnyechniygio" extra no código */}
              <span dangerouslySetInnerHTML={{ __html: t('common.feitoComAmor').replace('❤️', '<svg class="w-4 h-4 text-red-500 inline" fill="currentColor" viewBox="0 0 24 24"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>') }} />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;