import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function CV() {
  const { t, i18n } = useTranslation();

  // Função para download do PDF com suporte a idiomas
  const downloadCV = () => {
    // Seleciona o arquivo PDF correto com base no idioma atual
    const fileName = i18n.language === 'en' ? 'resume-catarina-lopes.pdf' : 'curriculo-catarina-lopes.pdf';
    const pdfUrl = `/${fileName}`;
    
    // Cria um elemento <a> temporário
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Download automático quando a página carrega
  useEffect(() => {
    downloadCV();
  }, []);

  return (
    <Container fluid className="py-12 px-0 bg-[#f5f2eb]">
      <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
            {t('cv.titulo')}
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            {t('cv.subtitulo')}
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <div className="mb-8">
              <svg 
                className="w-16 h-16 mx-auto mb-4 text-[#b4d3b2]" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              <p className="text-gray-600 mb-6">
                {t('cv.downloadAutomatico')}
              </p>
            </div>

            <button 
              onClick={downloadCV}
              className="inline-flex items-center px-6 py-3 bg-[#b4d3b2] text-white rounded-lg hover:bg-[#95c092] transition-colors"
            >
              {t('buttons.baixarCurriculo')}
              <svg 
                className="w-5 h-5 ml-2" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </button>

            <div className="mt-8 pt-6 border-t border-gray-200">
              <p className="text-sm text-gray-500">
                {t('cv.ultimaAtualizacao')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default CV;