import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Home() {
  const { t } = useTranslation();

  // Experiências definidas diretamente (em vez de buscá-las da tradução)
  // Isso evita problemas de renderização com objetos complexos
  const experiencias = [
    {
      id: 1,
      cargo: t('experiencias.0.cargo', "Pesquisadora e estágio de docência PAE"),
      empresa: t('experiencias.0.empresa', "Escola de Comunicações e Artes (ECA-USP)"),
      periodo: t('experiencias.0.periodo', "2023 - Presente"),
      descricao: t('experiencias.0.descricao', "Pesquisadora com bolsa CAPES e estagiária docente da disciplina de Teoria da Comunicação...")
    },
    {
      id: 2,
      cargo: t('experiencias.1.cargo', "Account Executive"),
      empresa: t('experiencias.1.empresa', "Edelman Brasil"),
      periodo: t('experiencias.1.periodo', "2022 - 2023"),
      descricao: t('experiencias.1.descricao', "Atuação como executiva de contas plena da Aegea...")
    },
    {
      id: 3,
      cargo: t('experiencias.2.cargo', "Assessora de imprensa"),
      empresa: t('experiencias.2.empresa', "EDB Comunicação"),
      periodo: t('experiencias.2.periodo', "2021 - 2022"),
      descricao: t('experiencias.2.descricao', "Assessora de imprensa para quatro clientes do ramo logístico...")
    },
    {
      id: 4,
      cargo: t('experiencias.3.cargo', "Bolsista de Iniciação Científica"),
      empresa: t('experiencias.3.empresa', "Lab404 - UFBA"),
      periodo: t('experiencias.3.periodo', "2020 - 2021"),
      descricao: t('experiencias.3.descricao', "Bolsista CNPq/INCT-DD de Iniciação Científica...")
    },
    {
      id: 5,
      cargo: t('experiencias.4.cargo', "Estagiária"),
      empresa: t('experiencias.4.empresa', "Grupo Metrópole"),
      periodo: t('experiencias.4.periodo', "2019 - 2019"),
      descricao: t('experiencias.4.descricao', "Entrava ao vivo na rádio com notícias curtas...")
    },
    {
      id: 6,
      cargo: t('experiencias.5.cargo', "Estagiária da Editoria Local"),
      empresa: t('experiencias.5.empresa', "Grupo A TARDE"),
      periodo: t('experiencias.5.periodo', "2018 - 2019"),
      descricao: t('experiencias.5.descricao', "Realizava a apuração e produção diária de pautas para a editoria de Cidades...")
    },
    {
      id: 7,
      cargo: t('experiencias.6.cargo', "Social Media Evaluator"),
      empresa: t('experiencias.6.empresa', "Appen"),
      periodo: t('experiencias.6.periodo', "2018 - 2018"),
      descricao: t('experiencias.6.descricao', "Realizava análise de publicidade no Facebook...")
    },
    {
      id: 8,
      cargo: t('experiencias.7.cargo', "Criadora Gráfica e Gerente Comercial"),
      empresa: t('experiencias.7.empresa', "Produtora Júnior"),
      periodo: t('experiencias.7.periodo', "2017 - 2018"),
      descricao: t('experiencias.7.descricao', "Como gerente comercial, fazia prospecção ativa para clientes...")
    }
  ];

  // Mock data para os destaques (usando os mesmos da página Textos)
  const destaques = [
    {
      id: 1,
      categoria: t('categorias.critica'),
      titulo: "Adoráveis Mulheres | Adaptação é calorosa e atual",
      descricao: "De cara, parece desnecessário mais uma adaptação de Little Women, livro de Louisa May Alcott publicado entre 1868 e 1869, adaptado sete vezes ao cinema. Mas Greta Gerwig (Lady Bird), diretora e roteirista da obra, entende seus temas sem se sentir obrigada a reformar o discurso...",
      data: "9 Jan 2020",
      link: "https://cinesiageek.com.br/adoraveis-mulheres-adaptacao-e-calorosa-e-atual/"
    },
    {
      id: 2,
      categoria: t('categorias.jornalismo'),
      titulo: "Trabalhadores dos arcos da Conceição temem despejo",
      descricao: "A requalificação dos arcos da ladeira da Conceição da Praia, no Centro Histórico de Salvador, voltou a ser discutida após declaração do prefeito...",
      data: "11 Fev 2019",
      link: "https://ipm-portal-municipio.s3.amazonaws.com/publicacoes/3912/B1E77B811ECC66BCBF862ED09552EE76.pdf"
    },
    {
      id: 3,
      categoria: t('categorias.artigoCientifico'),
      titulo: "Análise Crítica da Mídia na Era Digital",
      descricao: "Entre fancams de depoimentos e haters de advogados: uma análise dos TikToks mais populares nas semanas do julgamento Depp v. Heard...",
      data: "5 Set 2024",
      link: "https://sistemas.intercom.org.br/pdf/submissao/nacional/17/06282024135322667eea829c550.pdf"
    }
  ];

  // Configurações do carrossel para experiências
  const experienceSettings = {
    dots: true,
    infinite: false, // Desativa o loop infinito para manter a cronologia clara
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, // Desativa autoplay para melhor controle da timeline
    arrows: true,
    responsive: [
      {
        breakpoint: 640,
        settings: "unslick"
      }
    ]
  };

  // Agrupa experiências em conjuntos de 3
  const groupedExperiencias = experiencias.reduce((acc, exp, index) => {
    const groupIndex = Math.floor(index / 3);
    if (!acc[groupIndex]) {
      acc[groupIndex] = [];
    }
    acc[groupIndex].push(exp);
    return acc;
  }, []);

  // Componente TimelineGroup
  const TimelineGroup = ({ experiences }) => (
    <div className="relative">
      {experiences.map((exp, index) => (
        <div key={exp.id} className="relative pl-8 pb-12 last:pb-0">
          {/* Linha vertical - Verde escuro */}
          {index !== experiences.length - 1 && (
            <div className="absolute left-[11px] top-[30px] bottom-0 w-[2px] bg-[#253e23] opacity-20"></div>
          )}
          
          {/* Ponto na linha do tempo - Verde escuro */}
          <div className="absolute left-0 top-[6px] w-6 h-6 rounded-full bg-[#253e23] border-4 border-white shadow"></div>
          
          {/* Conteúdo */}
          <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex flex-wrap justify-between items-start mb-2">
              <h3 className="text-xl font-semibold text-gray-900">
                {exp.cargo}
              </h3>
              <span className="text-sm font-medium text-[#b4d3b2] bg-[#f0f7ef] px-3 py-1 rounded-full">
                {exp.periodo}
              </span>
            </div>
            <h4 className="text-lg text-gray-700 mb-2">
              {exp.empresa}
            </h4>
            <p className="text-gray-600">
              {exp.descricao}
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  // Configurações do carrossel para destaques
  const destaquesSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 640,
        settings: "unslick"
      }
    ]
  };

  // Componente Card para Destaques
  const DestaqueCard = ({ destaque }) => (
    <div className="px-2">
      <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow h-full">
        <div className="p-6 flex flex-col h-full">
          <span className="text-sm font-medium text-[#b4d3b2] bg-[#f0f7ef] px-3 py-1 rounded-full">
            {destaque.categoria}
          </span>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            {destaque.titulo}
          </h3>
          <p className="text-gray-600 mb-4 flex-grow">
            {destaque.descricao}
          </p>
          <div className="flex justify-between items-center mt-auto pt-4 border-t border-gray-100">
            <span className="text-sm text-gray-500">
              {destaque.data}
            </span>
            <Link 
              to={destaque.link}
              className="text-[#b4d3b2] hover:text-[#95c092] font-medium inline-flex items-center"
            >
              {t('buttons.leiaMais')}
              <svg 
                className="w-4 h-4 ml-1" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <Hero />

      {/* Seção de Experiência Profissional */}
      <section className="py-16 bg-white">
        <Container fluid className="px-0">
          <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
              {t('home.secoes.experienciaProfissional')}
            </h2>
            
            {/* Carrossel de grupos de timeline para desktop */}
            <div className="hidden sm:block">
              <Slider {...experienceSettings}>
                {groupedExperiencias.map((group, index) => (
                  <div key={index} className="px-4">
                    <TimelineGroup experiences={group} />
                  </div>
                ))}
              </Slider>
            </div>

            {/* Versão mobile com timeline completa */}
            <div className="sm:hidden">
              <TimelineGroup experiences={experiencias} />
            </div>
          </div>
        </Container>
      </section>

      {/* Seção de Destaques */}
      <section className="py-16 bg-[#f5f2eb]">
        <Container fluid className="px-0">
          <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
              {t('home.secoes.trabalhosDestaque')}
            </h2>
            
            {/* Carrossel para desktop */}
            <div className="hidden sm:block">
              <Slider {...destaquesSettings}>
                {destaques.map((destaque) => (
                  <DestaqueCard key={destaque.id} destaque={destaque} />
                ))}
              </Slider>
            </div>

            {/* Grid para mobile */}
            <div className="grid gap-6 sm:hidden">
              {destaques.map((destaque) => (
                <DestaqueCard key={destaque.id} destaque={destaque} />
              ))}
            </div>
          </div>
        </Container>
      </section>

      {/* Seção Sobre */}
      <section className="py-16 bg-white">
        <Container fluid className="px-0">
          <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                {t('home.secoes.sobreMeuTrabalho')}
              </h2>
              <p className="text-lg text-gray-600 mb-8 leading-relaxed">
                {t('home.sobre')}
              </p>
              <div className="flex flex-wrap justify-center gap-4">
                <Link
                  to="/cv"
                  className="inline-flex items-center px-6 py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
                >
                  {t('buttons.verCurriculo')}
                </Link>
                <Link
                  to="/texto"
                  className="inline-flex items-center px-6 py-3 bg-white border-2 border-gray-900 text-gray-900 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  {t('buttons.explorarPortfolio')}
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* Seção de Contato Rápido */}
      <section className="py-16 bg-[#f0f7ef]">
        <Container fluid className="px-0">
          <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                {t('home.secoes.vamosConversar')}
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                {t('home.contato')}
              </p>
              <a
                href="mailto:seu.email@exemplo.com"
                className="inline-flex items-center px-6 py-3 bg-[#b4d3b2] text-white rounded-lg hover:bg-[#95c092] transition-colors"
              >
                {t('buttons.enviarEmail')}
                <svg 
                  className="w-5 h-5 ml-2" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </a>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default Home;