import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import LanguageSwitcher from './LanguageSwitcher';

function NavbarComponent() {
  const [isOpen, setIsOpen] = useState(false);
  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false);
  const [isDesktopPortfolioOpen, setIsDesktopPortfolioOpen] = useState(false);
  const { t } = useTranslation();

  // Função para fechar o dropdown ao clicar em um item
  const handleLinkClick = () => {
    setIsOpen(false);
    setIsPortfolioOpen(false);
    setIsDesktopPortfolioOpen(false);
  };

  // Função para alternar o dropdown de portfólio desktop
  const toggleDesktopPortfolio = (e) => {
    e.preventDefault();
    setIsDesktopPortfolioOpen(!isDesktopPortfolioOpen);
  };

  return (
    <nav className="bg-white border-b">
      <Container className="py-3">
        <div className="flex items-center justify-between px-4">
          {/* Logo/Nome */}
          <Link to="/" className="text-xl font-bold text-gray-800">
            {t('common.nome')}
          </Link>

          {/* Links de Navegação */}
          <div className="hidden lg:flex items-center space-x-8">
            <Link to="/" className="text-gray-600 hover:text-gray-800 transition-colors">
              {t('navigation.inicio')}
            </Link>
            
            {/* Dropdown Portfólio - Desktop */}
            <div className="relative">
              <button 
                className="text-gray-600 hover:text-gray-800 transition-colors py-2 flex items-center"
                onClick={toggleDesktopPortfolio}
              >
                {t('navigation.portfolio')}
                <svg
                  className={`w-4 h-4 ml-1 transition-transform duration-200 ${
                    isDesktopPortfolioOpen ? 'transform rotate-180' : ''
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              
              {/* Submenu Desktop - agora com toggle por clique */}
              {isDesktopPortfolioOpen && (
                <div className="absolute left-0 top-full w-48 bg-white py-2 shadow-lg rounded-lg z-10">
                  <Link 
                    to="/texto" 
                    className="block px-4 py-2 text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                    onClick={handleLinkClick}
                  >
                    {t('navigation.textos')}
                  </Link>
                  <Link 
                    to="/insercoes" 
                    className="block px-4 py-2 text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                    onClick={handleLinkClick}
                  >
                    {t('navigation.insercoesNaMidia')}
                  </Link>
                  <Link 
                    to="/reel" 
                    className="block px-4 py-2 text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                    onClick={handleLinkClick}
                  >
                    {t('navigation.resumeReel')}
                  </Link>
                </div>
              )}
            </div>

            <Link to="/cv" className="text-gray-600 hover:text-gray-800 transition-colors">
              {t('navigation.cv')}
            </Link>
            <Link to="/lattes" className="text-gray-600 hover:text-gray-800 transition-colors">
              {t('navigation.lattes')}
            </Link>
            {/* Language Switcher - Desktop */}
            <LanguageSwitcher />
          </div>

          {/* Botão Mobile */}
          <button 
            className="lg:hidden p-2" 
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        {/* Menu Mobile */}
        {isOpen && (
          <div className="lg:hidden mt-4 px-4 pb-4">
            <Link 
              to="/" 
              className="block py-2 text-gray-600 hover:text-gray-800"
              onClick={handleLinkClick}
            >
              {t('navigation.inicio')}
            </Link>
            
            {/* Portfólio Mobile com Toggle */}
            <div className="py-2">
              <button
                className="flex items-center justify-between w-full text-gray-600 hover:text-gray-800"
                onClick={() => setIsPortfolioOpen(!isPortfolioOpen)}
              >
                <span className="font-medium">{t('navigation.portfolio')}</span>
                <svg
                  className={`w-4 h-4 transition-transform duration-200 ${
                    isPortfolioOpen ? 'transform rotate-180' : ''
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              
              {/* Submenu Mobile */}
              {isPortfolioOpen && (
                <div className="pl-4 mt-2 space-y-2">
                  <Link 
                    to="/texto" 
                    className="block text-gray-600 hover:text-gray-800 py-1"
                    onClick={handleLinkClick}
                  >
                    {t('navigation.textos')}
                  </Link>
                  <Link 
                    to="/insercoes" 
                    className="block text-gray-600 hover:text-gray-800 py-1"
                    onClick={handleLinkClick}
                  >
                    {t('navigation.insercoesNaMidia')}
                  </Link>
                  <Link 
                    to="/reel" 
                    className="block text-gray-600 hover:text-gray-800 py-1"
                    onClick={handleLinkClick}
                  >
                    {t('navigation.resumeReel')}
                  </Link>
                </div>
              )}
            </div>

            <Link 
              to="/cv" 
              className="block py-2 text-gray-600 hover:text-gray-800"
              onClick={handleLinkClick}
            >
              {t('navigation.cv')}
            </Link>
            <Link 
              to="/lattes" 
              className="block py-2 text-gray-600 hover:text-gray-800"
              onClick={handleLinkClick}
            >
              {t('navigation.lattes')}
            </Link>
            
            {/* Language Switcher - Mobile */}
            <div className="mt-4 pt-4 border-t border-gray-200">
              <div className="flex items-center">
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        )}
      </Container>
    </nav>
  );
}

export default NavbarComponent;