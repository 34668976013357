import { Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Texto() {
  const { t } = useTranslation();
  
  // Artigos com categorias traduzidas
  const artigos = [
    {
      id: 1,
      titulo: "💥 VELA WANDAVISION 💥",
      resumo: "Direto do Hex e com origens sokovianas, a vela do maior ato do Marvel Cinematic Universe chegou na Faerie 💥💥 Quem mais vai morrer de saudades dessa série impecável?...",
      data: "5 Mar 2021",
      categoria: t('categorias.copywriting'),
      imagem: "/caminho/para/sua/imagem1.jpg",
      link: "https://www.instagram.com/p/CMDPEeYDyQj/?img_index=1"
    },
    {
      id: 2,
      titulo: "Trabalhadores dos arcos da Conceição temem despejo",
      resumo: "A requalificação dos arcos da ladeira da Conceição da Praia, no Centro Histórico de Salvador, voltou a ser discutida após declaração do prefeito...",
      data: "11 Fev 2019",
      categoria: t('categorias.jornalismo'),
      imagem: "/caminho/para/sua/imagem2.jpg",
      link: "https://ipm-portal-municipio.s3.amazonaws.com/publicacoes/3912/B1E77B811ECC66BCBF862ED09552EE76.pdf"
    },
    {
      id: 3,
      titulo: "Sala 404 - Edição 3",
      resumo: "Olá! Você está recebendo mais uma edição da SALA 404, newsletter quinzenal do Lab404/UFBA. Nela destacamos o canal #fakenews, com a reportagem Como a extrema direita burla punições do YouTube, publicada pelo The Intercept Brasil, e o canal #plataformas, com YouTube anuncia remoção de vídeos que recomendem hidroxicloroquina e ivermectina para tratar covid, publicada pelo Estadão...",
      data: "26 Abr 2021",
      categoria: t('categorias.seoContentWriting'),
      imagem: "/caminho/para/sua/imagem3.jpg",
      link: "https://mailchi.mp/b2d55053de6e/2642021-sala-404-newsletter-quinzenal-do-lab404ufba?e=a0e438d9b2"
    },
    {
      id: 4,
      titulo: "Quem toma vacina ganha desconto!",
      resumo: "A Faerie quer ver todo mundo vacinado e vai fazer sua parte: 20% OFF pra quem mandar o comprovante de vacinação no direct!...",
      data: "17 Ago 2021",
      categoria: t('categorias.copywriting'),
      imagem: "/caminho/para/sua/imagem4.jpg",
      link: "https://www.instagram.com/faeriecandleco/p/CSsgYa3LfCj/"
    },
    {
      id: 5,
      titulo: "DEA SYSTEMS - Produtos e serviços",
      resumo: "Serviços de reparo: Sino atomizador, Turbina de pintura, Bomba dosadora volumétrica, Blocos aplicadores de pintura, Blocos e válvulas de troca de cor...",
      data: "20 Jun 2023",
      categoria: t('categorias.redacaoUX'),
      imagem: "/caminho/para/sua/imagem5.jpg",
      link: "https://www.deasystems.com.br/produtos-e-servi%C3%A7os"
    },
    {
      id: 6,
      titulo: "Povo de santo pede paz durante caminhada em Salvador",
      resumo: "Representantes de 20 terreiros de candomblé situados no bairro do Engenho Velho da Federação, em Salvador, realizaram na tarde desta quinta-feira, 15, a 14ª caminhada em protesto à intolerância religiosa, pelo fim da violência e a favor da paz....",
      data: "15 Nov 2018",
      categoria: t('categorias.jornalismo'),
      imagem: "/caminho/para/sua/imagem6.jpg",
      link: "https://atarde.com.br/bahia/bahiasalvador/povo-de-santo-pede-paz-durante-caminhada-em-salvador-1009679"
    },
    {
      id: 7,
      titulo: "Adoráveis Mulheres | Adaptação é calorosa e atual",
      resumo: "De cara, parece desnecessário mais uma adaptação de Little Women, livro de Louisa May Alcott publicado entre 1868 e 1869, adaptado sete vezes ao cinema. Mas Greta Gerwig (Lady Bird), diretora e roteirista da obra, entende seus temas sem se sentir obrigada a reformar o discurso....",
      data: "9 Jan 2020",
      categoria: t('categorias.critica'),
      imagem: "/caminho/para/sua/imagem7.jpg",
      link: "https://cinesiageek.com.br/adoraveis-mulheres-adaptacao-e-calorosa-e-atual/"
    },
    {
      id: 8,
      titulo: "O Coringa é incel?",
      resumo: "ATENÇÃO: ESSE TEXTO CONTÉM SPOILERS DE CORINGA (2019). Se você ainda não viu o filme, assista e aí volte para ler o texto. Respondendo logo a pergunta do título: não. Mas não adianta só dizer isso sem argumentar, né? De qualquer forma, só para deixar bastante claro: o Coringa do Joaquin Phoenix não é incel....",
      data: "5 Outubro 2019",
      categoria: t('categorias.artigoOpiniao'),
      imagem: "/caminho/para/sua/imagem8.jpg",
      link: "https://https://cinesiageek.com.br/o-coringa-e-incel/-site-ou-medium.com/artigo-8"
    },
    {
      id: 9,
      titulo: "Análise Crítica da Mídia na Era Digital",
      resumo: "Entre fancams de depoimentos e haters de advogados: uma análise dos TikToks mais populares nas semanas do julgamento Depp v. Heard...",
      data: "5 Set 2024",
      categoria: t('categorias.artigoCientifico'),
      imagem: "/caminho/para/sua/imagem9.jpg",
      link: "https://sistemas.intercom.org.br/pdf/submissao/nacional/17/06282024135322667eea829c550.pdf"
    },
    {
      id: 10,
      titulo: "Euphoria | Skins para Gen Zs",
      resumo: "Desde que adolescentes passaram a ser percebidos como um público diferenciado – algo entre crianças e adultos – e, consequentemente, um mercado, existe um conteúdo que é marcante para aquela geração. Algo que, pela primeira vez, representou e validou as preocupações específicas de cada geração....",
      data: "10 Agosto 2019",
      categoria: t('categorias.critica'),
      imagem: "/caminho/para/sua/imagem8.jpg",
      link: "https://cinesiageek.com.br/euphoria-skins-para-gen-zs/"
    }
  ];

  // Componente Card atualizado
  const ArtigoCard = ({ artigo }) => {
    const { t } = useTranslation();
    const [screenshotUrl, setScreenshotUrl] = useState(null);

    useEffect(() => {
      const fetchScreenshot = async () => {
        try {
          const response = await fetch(
            `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(artigo.link)}&strategy=mobile&category=PERFORMANCE`
          );
          const data = await response.json();
          if (data.lighthouseResult?.audits['final-screenshot']?.details?.data) {
            setScreenshotUrl(data.lighthouseResult.audits['final-screenshot'].details.data);
          }
        } catch (error) {
          console.error('Erro ao carregar screenshot:', error);
        }
      };

      fetchScreenshot();
    }, [artigo.link]);

    return (
      <article className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col">
        <a 
          href={artigo.link}
          target="_blank"
          rel="noopener noreferrer"
          className="block aspect-[16/9] overflow-hidden bg-gray-100"
        >
          {screenshotUrl ? (
            <img
              src={screenshotUrl}
              alt={artigo.titulo}
              className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
              loading="lazy"
            />
          ) : (
            <div className="w-full h-full bg-gray-100 animate-pulse flex items-center justify-center">
              <svg 
                className="w-12 h-12 text-gray-300" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
          )}
        </a>

        <div className="p-4 sm:p-6 flex flex-col flex-grow">
          <div className="flex items-center justify-between text-sm text-gray-500 mb-3">
            <span className="text-sm font-medium text-[#b4d3b2] bg-[#f0f7ef] px-3 py-1 rounded-full">
              {artigo.categoria}
            </span>
            <span className="text-xs sm:text-sm">{artigo.data}</span>
          </div>

          <h2 className="text-lg sm:text-xl font-semibold text-gray-900 mb-3 line-clamp-2">
            <a 
              href={artigo.link}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#b4d3b2] transition-colors"
            >
              {artigo.titulo}
            </a>
          </h2>

          <p className="text-gray-600 mb-4 line-clamp-3 text-sm sm:text-base flex-grow">
            {artigo.resumo}
          </p>

          <div className="flex items-center justify-end mt-auto pt-4 border-t border-gray-100">
            <a
              href={artigo.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#b4d3b2] hover:text-[#95c092] font-medium text-sm"
            >
              {t('buttons.lerMais')}
              <svg 
                className="w-4 h-4 ml-1 inline" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </a>
          </div>
        </div>
      </article>
    );
  };

  return (
    <Container fluid className="py-12 px-0 bg-[#f5f2eb]">
      <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
            {t('texto.titulo')}
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            {t('texto.subtitulo')}
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {artigos.map((artigo) => (
            <ArtigoCard key={artigo.id} artigo={artigo} />
          ))}
        </div>
      </div>
    </Container>
  );
}

export default Texto;