import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex items-center space-x-2">
      <button 
        className={`px-2 py-1 rounded ${i18n.language === 'pt' ? 'bg-[#b4d3b2] text-white' : 'text-gray-600'}`}
        onClick={() => changeLanguage('pt')}
      >
        PT
      </button>
      <button 
        className={`px-2 py-1 rounded ${i18n.language === 'en' ? 'bg-[#b4d3b2] text-white' : 'text-gray-600'}`}
        onClick={() => changeLanguage('en')}
      >
        EN
      </button>
    </div>
  );
}

export default LanguageSwitcher;