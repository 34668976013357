import { Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function InsercoesNaMidia() {
  const { t } = useTranslation();
  
  const insercoes = [
    {
      id: 1,
      titulo: "Estadão - Move3",
      veiculo: "Estadão",
      data: "11 Abril 2022",
      descricao: "A caminho de uma oferta inicial de ações (IPO, na sigla em inglês), a empresa de logística Move3 adquiriu a startup Go X Crossborder, especializada em soluções para...",
      tipo: t('categorias.noticia'),
      link: "https://www.estadao.com.br/economia/coluna-do-broad/em-preparacao-para-o-ipo-grupo-move3-compra-startup-e-preve-aquisicoes/"
    },
    {
      id: 2,
      titulo: "Valor Econômico - Aegea",
      veiculo: "Valor Econômico",
      data: "15 Julho 2022",
      descricao: "Desde que o novo marco legal do saneamento básico foi aprovado, há três anos, a Aegea saiu vencedora em sete dos 13 leilões regionais realizados pelo país. Ao todo, foram ao menos R$ 45,7 bilhões de investimentos contratados para os próximos anos, fora os R$ 19,55 bilhões em outorgas e pagamentos já desembolsados...",
      tipo: t('categorias.noticia'),
      link: "https://valor.globo.com/empresas/noticia/2023/07/15/aegea-chega-a-r-46-bilhes-de-investimentos-contratados-desde-2020.ghtml"
    },
    {
      id: 3,
      titulo: "Folha de S.Paulo - Nassau Empreendimentos",
      veiculo: "Folha de S.Paulo",
      data: "20 Janeiro 2022",
      descricao: "Mais de 5.500 lojas de shoppings pelo país tiveram de fechar as portas temporariamente por falta de funcionários contaminados com Covid ou gripe...",
      tipo: t('categorias.noticia'),
      link: "https://www1.folha.uol.com.br/colunas/painelsa/2022/01/mais-de-5500-lojas-de-shopping-fecham-por-falta-de-funcionarios-apos-avanco-da-omicron.shtml"
    },
    {
      id: 4,
      titulo: "Valor Investe - N2",
      veiculo: "Valor Investe",
      data: "2 Junho 2022",
      descricao: "Mais de 24 mil caixas eletrônicos buscados para serviços como saque de dinheiro, pagamento de boleto, ou emissão de extrato, por exemplo, também passam a ser canais para a orientação financeira. Um serviço recém-lançado oferece a possibilidade de usuários de 150 instituições financeiras contratarem pacote com conteúdo e atendimento personalizado para organização das finanças pessoais...",
      tipo: t('categorias.noticia'),
      link: "https://valorinveste.globo.com/educacao-financeira/noticia/2022/06/02/caixas-eletronicos-dao-acesso-a-orientacao-financeira-por-valor-menor-que-recarga-de-celular.ghtml"
    },
    {
      id: 5,
      titulo: "Estadão - Move3",
      veiculo: "Estadão",
      data: "23 Fevereiro 2022",
      descricao: "Guilherme Juliani, CEO a MOVE3 Foto: MOVE3/divulgação. Com negócios voltados para logística, inclusive o chamado last mile (chegada da encomenda na casa do comprador), a brasileira MOVE3 separou R$ 70 milhões para a automação de seu novo centro de distribuição em São Bernardo do Campo (SP). Com previsão de receita de R$ 1,1 bilhão em 2022, a companhia - que tem na carteira de clientes grandes empresas como Itaú, Nubank, Arezzo, Havaianas e indústrias farmacêuticas - também reservo...",
      tipo: t('categorias.noticia'),
      link: "https://www.estadao.com.br/economia/coluna-do-broad/com-receita-bilionaria-empresa-familiar-de-logistica-prepara-aquisicoes/"
    },
    {
      id: 6,
      titulo: "Exame -  N2",
      veiculo: "Exame",
      data: "10 Mar 2024",
      descricao: "Cada vez mais brasileiros se veem inclinados a entrar no mundo dos investimentos e aprender a melhor forma de fazê-lo. Desde 2020, a poupança está rendendo menos que a inflação, ou seja, quem coloca dinheiro na poupança vem perdendo poder de compra. A crença de que o cidadão médio investe apenas na poupança e deixa outras modalidades para os especialistas foi deixada para trás — em 2019, a B3 bateu a marca de 1 milhão de pessoas físicas na renda variável, e o número chegou a 3,8 milhões de contas em junho deste ano...",
      tipo: t('categorias.noticia'),
      link: "https://exame.com/bussola/vai-investir-pela-primeira-vez-veja-quais-sao-suas-perspectivas-para-2022/"
    }
  ];

  // Componente Card atualizado com preview
  const InsercaoCard = ({ insercao }) => {
    const [screenshotUrl, setScreenshotUrl] = useState(null);

    useEffect(() => {
      const fetchScreenshot = async () => {
        try {
          const response = await fetch(
            `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(insercao.link)}&strategy=mobile&category=PERFORMANCE`
          );
          const data = await response.json();
          if (data.lighthouseResult?.audits['final-screenshot']?.details?.data) {
            setScreenshotUrl(data.lighthouseResult.audits['final-screenshot'].details.data);
          }
        } catch (error) {
          console.error('Erro ao carregar screenshot:', error);
        }
      };

      fetchScreenshot();
    }, [insercao.link]);

    return (
      <article 
        key={insercao.id}
        className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
      >
        {/* Preview da mídia */}
        <a 
          href={insercao.link}
          target="_blank"
          rel="noopener noreferrer"
          className="block aspect-[16/9] overflow-hidden bg-gray-100"
        >
          {screenshotUrl ? (
            <img
              src={screenshotUrl}
              alt={insercao.titulo}
              className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
              loading="lazy"
            />
          ) : (
            <div className="w-full h-full bg-gray-100 animate-pulse flex items-center justify-center">
              <svg 
                className="w-12 h-12 text-gray-300" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
          )}
        </a>

        <div className="p-6">
          <div className="flex justify-between items-start mb-4">
            <span className="text-sm font-medium text-[#b4d3b2] bg-[#f0f7ef] px-3 py-1 rounded-full">
              {insercao.tipo}
            </span>
            <span className="text-sm text-gray-500">
              {insercao.data}
            </span>
          </div>

          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            {insercao.titulo}
          </h2>

          <h3 className="text-lg text-gray-700 mb-3">
            {insercao.emissora}
          </h3>

          <p className="text-gray-600 mb-4">
            {insercao.descricao}
          </p>

          <a
            href={insercao.link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-[#b4d3b2] hover:text-[#95c092] font-medium"
          >
            {t('buttons.saibaMais')}
            <svg 
              className="w-4 h-4 ml-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
        </div>
      </article>
    );
  };

  return (
    <Container fluid className="py-12 px-0 bg-[#f5f2eb]">
      <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
            {t('insercoesMidia.titulo')}
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            {t('insercoesMidia.subtitulo')}
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {insercoes.map((insercao) => (
            <InsercaoCard key={insercao.id} insercao={insercao} />
          ))}
        </div>
      </div>
    </Container>
  );
}

export default InsercoesNaMidia;